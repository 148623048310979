<template>
  <!-- 字典类型界面 -->
  <div class="create-screen">
    <div v-loading.fullscreen.lock="fullscreenLoading">
      <div class="table-layout">
        <el-row :gutter="20">
          <el-form
            ref="sensorForm"
            :model="sensorForm"
            label-width="100px"
            @submit.native.prevent
            :inline="true"
          >
            <el-row class="btnRow">
              <el-col :span="5" class="elrow">
                <el-form-item
                  label="唯一编码"
                  prop="searchInfo"
                  style="padding-left: 10px"
                >
                  <el-input
                    v-model="sensorForm.searchInfo"
                    size="mini"
                    clearable
                    @clear="clearAll"
                    style="width: 160px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <search-button
                @exportEvent="exportEvent"
                @importEvent="importEvent"
                @searchEvent="searchSenser"
                :showAll="showAll"
                @closepop="closepop"
              ></search-button>
            </el-row>
          </el-form>
        </el-row>
        <br/>
        <el-row style="margin-bottom: 20px">
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              v-if="hasPermission('add')"
              type="text"
              icon="el-icon-plus"
              size="mini"
              class="editBtn"
              @click="addVisiableSensor"
              >新增</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              type="text"
              v-if="hasPermission('edit')"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              @click="editSensor"
              >修改</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              v-if="hasPermission('delete')"
              type="text"
              class="deleteBtn"
              icon="el-icon-delete"
              size="mini"
              @click="delSensor"
              >删除</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              v-if="hasPermission('bangding')"
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              @click="bangdingSensor"
              >绑定具体值</el-button
            >
          </el-col>
        </el-row>

        <el-table
          :data="yList"
          :cell-style="{ 'text-align': 'center' }"
          :row-style="getRowClass"
          :header-row-style="getRowClass"
          :header-cell-style="getRowClass"
          @selection-change="handleSelectionChangeNode"
          border
          height="500px"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column min-width="4%" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column min-width="8%" label="类型名称"
            ><template slot-scope="scope">{{
              scope.row.tripartite_parameters_type
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="标识码"
            ><template slot-scope="scope">{{
              scope.row.explain
            }}</template></el-table-column
          >
          <!-- <el-table-column min-width="7%" label="具体值"
            ><template slot-scope="scope">{{
              scope.row.tripartite_parameters
            }}</template></el-table-column
          >
          <el-table-column v-if="false" label="具体值id"
            ><template slot-scope="scope">{{
              scope.row.tripartite_parameters_id
            }}</template></el-table-column
          > -->
          <el-table-column v-if="false" label="类型id"
            ><template slot-scope="scope">{{
              scope.row.parameters_type_id
            }}</template></el-table-column
          >
        </el-table>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-size="pageSize"
            layout="prev,pager,next,total,jumper"
            :total="tableData.length"
          >
          </el-pagination>
        </div>
      </div>
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="centerDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="150px"
              :model="sensorForm"
            >
              <el-form-item label="类型:" :required="true" prop="entity">
              </el-form-item>
              <el-form-item label="上传xlsx文件:">
                <el-upload
                  ref="upload"
                  action="#"
                  :auto-upload="false"
                  :multiple="false"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  class="upload-demo uploadImages"
                  :http-request="uploadHttpRequest"
                  :on-remove="handleRemove"
                  :on-change="handleChange"
                >
                  <el-button slot="trigger" class="btn-text" type="primary"
                    >选取文件</el-button
                  >
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="btn-text" @click="submitUpload"
                  >上传</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>

      <!--新增单条设备信息管理-->
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="singleAddDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="openDigl">
            <div class="item1">
              <br />
              <el-form
                label-position="left"
                label-width="100px"
                :model="dicForm"
              >
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="所属类型:" :required="true">
                      <!-- <el-select
                        :popper-append-to-body="false"
                        clearable
                        filterable
                        allow-create
                        default-first-option
                        style="width: 100%"
                        v-model="dicForm.parameters_type_id"
                        @change="changeType"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in siteArr"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select> -->
                      <el-input
                        class="btn-text"
                        v-model="dicForm.tripartite_parameters_type"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="标识码:" :required="true">
                      <el-input
                        class="btn-text"
                        v-model="dicForm.explain"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item>
                      <el-button
                        type="primary"
                        class="btn-text"
                        @click="handleClick"
                        >{{ addorEdittext }}</el-button
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 5、绑定区域-->
    <!--绑定弹窗需要加载用户所需能看到的菜单或者路由-->
    <div>
      <el-dialog
        title="绑定传感器"
        :append-to-body="true"
        :visible.sync="shouquanDialogVisiable"
        custom-class="custom-dialog"
        width="40%"
        center
      >
        <el-form>
          <el-form-item>
            <el-tree
              node-key="tripartite_parameters_id"
              ref="tree"
              :data="menuList"
              :props="menuProps"
              :default-checked-keys="defaultKey"
              :default-expanded-keys="[]"
              show-checkbox
            ></el-tree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn-text" @click="shouquanEvent">{{
              addorEditshoquan
            }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  searchAllDicV,
  getdicDataByExplain,
  getdicData,
  addDicK,
  addkDicV,
  deleteDicK,
  editDicK,
  searchparametersType
} from "@/api/index";
import SearchButton from "./tool/button.vue";
export default {
  data() {
    return {
      fullscreenLoading: false,
      pageSize: 20,
      currentPage1: 1,
      selctionNodeRows: [],
      nodeids: [],
      defaultKey: [],
      centerDialogVisible: false,
      singleAddDialogVisible: false,
      addorEdittext: "新增",
      fileList: [],
      tableData: [],
      shouquanDialogVisiable: false,
      addorEdittext: "新增",
      addorEditshoquan: "绑定",
      originTableData: [],
      dicForm: {
        parameters_type_id: "",
        explain: "",
        tripartite_parameters_type: "",
      },
      menuProps: {
        label: "tripartite_parameters",
        children: "children",
      },
      menuList: [],
      yList: [],
      //查询条件form
      sensorForm: {
        iditifyId: "",
        xinghao: "",
      },
      dataloaded: false,
      siteArr: [],
      typeoptions: [
        { id: "1", name: "tev" },
        { id: "2", name: "ae" },
        { id: "3", name: "hfct" },
        { id: "4", name: "tem" },
      ],
      onlineOptions: [
        { label: "在线", value: "1" },
        { label: "离线", value: "2" },
        { label: "故障", value: "3" },
      ],
      uploadoptions: [
        { id: "1", name: "正常" },
        { id: "2", name: "异常" },
        { id: "3", name: "中断" },
      ],
      showAll: true,
    };
  },
  components: {
    SearchButton,
  },
  computed: {
    tableHeight() {
      if (this.showAll) {
        return window.innerHeight - 140;
      } else {
        return window.innerHeight - 200;
      }
    },
  },
  watch: {
    defaultKey: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(newVal);
          });
        }
      },
      immediate: false,
      deep: true,
    },
  },
  mounted() {
    this.getSensorType();
    this.getSiteArr();
  },
  filters: {
    timeFormat: (value) => {
      // return value ? value.substring(0,value.indexOf(".")).replace('T',' ') : ''
      return value ? value.replace("T", " ") : "";
    },
  },
  methods: {
    getSiteArr() {
      if (!this.dataloaded) {
        // 请求所有类型名
        getdicData()
          .then((res) => {
            this.dataloaded = true;
            if (res.code == 200) {
              if (res.data.length > 0) {
                res.data.forEach((v) => {
                  this.siteArr.push({
                    label: v.tripartite_parameters_type,
                    value: v.parameters_type_id,
                  });
                });
              }
            } else {
              this.$message.error(res.error);
            }
          })
          .catch((err) => {
            this.dataloaded = true;
            console.log(err);
          });
      }
    },
    hasPermission(permissionName) {
      let elementUi = [];
      let arr = this.formatTree(this.$store.getters.permissions, "0");
      arr.forEach((v) => {
        if (v.attribute_name == "台账管理") {
          v.children.forEach((m) => {
            if (m.attribute_name == "字典类型") {
              m.children.forEach((j) => {
                elementUi.push(j.remarks);
              });
            }
          });
        }
      });
      return elementUi.includes(permissionName);
    }, // 后端反的格式转化成数组嵌套
    formatTree(arr, attribute_id) {
      let newArr = [];
      arr.forEach((item) => {
        if (item.parent_id == attribute_id) {
          item.children = this.formatTree(arr, item.attribute_id);
          newArr.push({
            ...item,
          });
        }
      });
      return newArr;
    },
    // 加载表格数据
    getSensorType() {
      getdicData()
        .then((res) => {
          let tableData = [];
          if (res.code == 200) {
            if (res.data.length > 0) {
              res.data.forEach((v) => {
                tableData.push({
                  parameters_type_id: v.parameters_type_id,
                  tripartite_parameters_type: v.tripartite_parameters_type,
                  explain: v.explain,
                });
              });
              this.tableData = tableData;
              this.originTableData = this.tableData;
              this.yList = this.tableData.slice(
                (this.currentPage1 - 1) * this.pageSize,
                this.currentPage1 * this.pageSize
              );
            }
          } else {
            this.$message.error(res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 单条节点管理
    handleSelectionChangeNode(selection) {
      this.selctionNodeRows = selection;
      console.log(selection,'selection');
      
      this.nodeids = selection.map((item) => item.id); // 需要根据数据情况调整id名称
    },
    // 文件上传之前的操作
    beforeUpload(file) {
      // 文件大小限制为20M
      const fileLimit = file.size / 1024 / 1024 < 2000;
      if (!fileLimit) {
        console.log("上传文件大小不超过2000M！");
      }
      // 返回判断条件,false停止上传
      // return fileType && fileLimit;
      return fileLimit;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 新增传感器弹窗信息
    addVisiableSensor() {
      this.addorEdittext = "新增";
      this.dicForm = {
        parameters_type_id: "",
        tripartite_parameters_type: "",
        explain: "",
      };
      this.singleAddDialogVisible = true;
    },
    // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
    uploadHttpRequest(param) {
      // FormData对象
      let formData = new FormData();
      formData.append("file", param.file);
      // formData.append("entity", this.nodeForm.entity);
      uploadFormData(formData)
        .then((res) => {
          var msg = "";
          if (res.code === 200) {
            this.$message.success("上传成功");
            // this.nodeForm.entity = "";
            this.fileList = [];
            this.centerDialogVisible = false;
            // this.getTableData();
          } else {
            msg = res.error;
            this.$message.error("上传失败！原因是" + msg);
            this.fileList = [];
          }
        })
        .catch((err) => {
          this.$refs.upload.clearFiles();
          this.fileList = [];
        });
    },
    // 上传文件
    submitUpload() {
      // 判断是否选择了文件，若没有，则后边支持导入
      if (this.fileList.length < 1) {
        this.$message.warning("请选择上传的文件！");
      } else {
        // 显示散点图谱
        this.centerDialogVisible = false;
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      }
    },

    // 新增传感器信息
    addSensor() {
      addDicK(this.dicForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getSensorType();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    // 修改站点信息弹窗
    editSensor() {
      console.log(this.dicForm);
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.dicForm.explain = this.selctionNodeRows[0].explain;
        this.dicForm.parameters_type_id =
          this.selctionNodeRows[0].parameters_type_id;
        this.dicForm.tripartite_parameters_type =
          this.selctionNodeRows[0].tripartite_parameters_type;
        this.singleAddDialogVisible = true;
        this.addorEdittext = "修改";
      }
    },
    // 批量删除或删除单个传感器
    delSensor() {
      console.log(this.selctionNodeRows);
      if (this.nodeids.length == 0) {
        this.$message.warning("请至少选择一条数据进行删除操作！");
      } else {
        let arr = [];
        this.selctionNodeRows.forEach((v) => {
          arr.push(v.parameters_type_id);
        });
        deleteDicK({
          parameters_type_ids: arr,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              // 请求表格数据
              this.getSensorType();
            } else {
              this.$message.error(res.error);
            }
          })
          .catch(() => {});
      }
    },
    // 绑定传感器和设备
    bangdingSensor() {
      // 打开弹窗 绑定的弹窗加载设备列表，选中一个设备绑定即可
      // 加载所有权限列表
      searchAllDicV().then((res) => {
        if (res.code == 200) {
          this.menuList = res.data;
        }
      });
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.defaultKey = [];
        // 判断一下，如果有过绑定的话，那么就改成修改绑定；如果没有绑定的话，还是绑定；通过查询绑定结果得到的结果不为空
        getdicDataByExplain(this.selctionNodeRows[0].explain)
          .then((res) => {
            if (res.code == 200) {
              if (res.data.length > 0) {
                res.data.forEach((v) => {
                  this.defaultKey.push(v.tripartite_parameters_id);
                });
                console.log(this.defaultKey);
                this.shouquanDialogVisiable = true;
                this.addorEditshoquan = "修改绑定";
                // 如果有可能的话，这块需要把绑定赋值过去，以便用户修改
              } else {
                this.shouquanDialogVisiable = true;
                this.addorEditshoquan = "绑定";
                // 这里就是加载所有权限列表，以供用户在列表中单选
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      return "background:#3f5c6d2c;text-align:center";
    },
    formatMM(array, key) {
      return array.reduce((result, currentItem) => {
        // 使用 key 作为分类的标识，找到对应的分类数组，如果不存在则创建
        const bucket = result.find(
          (item) => item[key] === currentItem[key]
        ) || { ...currentItem, children: [] };

        // 将当前项加入到对应分类的 children 数组中
        if (!bucket[key]) {
          bucket[key] = currentItem[key];
        }
        bucket.children.push(currentItem);

        // 如果 bucket 不在结果数组中，则添加进去
        if (!result.includes(bucket)) {
          result.push(bucket);
        }

        return result;
      }, []);
    },
    formatM(arr, collection_terminal_type) {
      return arr.reduce((acc, item) => {
        const parentId = item[collection_terminal_type];
        const parent = acc.find(
          (p) => p[collection_terminal_type] === parentId
        ) || { [collection_terminal_type]: parentId, children: [] };
        if (!acc.includes(parent)) {
          acc.push(parent);
        }
        parent.children
          ? parent.children.push(item)
          : (parent.children = [item]);
        return acc;
      }, []);
    },
    handleClick() {
      if (this.addorEdittext == "新增") {
        this.addSensor();
      } else {
        this.relationEdit();
      }
    },
    // 修改k值
    relationEdit() {
      // 使用链接put 修改
      editDicK(this.dicForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功！");
          // 关闭弹窗 加载列表
          this.singleAddDialogVisible = false;
          this.getSensorType();
        }
      });
    },
    handleSizeChange1(val) {
      this.currentPage1 = 1;
      this.pageSize = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    editBtn(row) {
      console.log(row);
    },
    deleteBtn(row) {
      console.log(row);
    },
    closepop() {
      this.showAll = !this.showAll;
      console.log("父组件的状态：" + this.showAll);
    },
    searchSenser() {
      this.searchparametersTypeInfo()
      console.log("您进入了父组件，点击了查询之后可以得到111111111111：" + this.sensorForm);
    },
    //模糊查询字典类型信息
    searchparametersTypeInfo() {
      searchparametersType(this.sensorForm.searchInfo).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.yList = res.data;
          this.currentPage1 = 1;
        }else{
          this.$message.error(res.error);
        }
      })
    },
    // 绑定事件
    shouquanEvent() {
      // 绑定事件
      if (this.addorEditshoquan == "绑定") {
        this.addOneAuth();
      } else {
        this.editOneAuth();
      }
    },
    // 新增绑定
    addOneAuth() {
      let params = {
        parameters_type_id: this.selctionNodeRows[0].parameters_type_id,
        tripartite_parameters_ids: [],
      };
      this.$refs.tree.getCheckedNodes().forEach((v) => {
        params.tripartite_parameters_ids.push(v.tripartite_parameters_id);
      });
      params.tripartite_parameters_ids = Array.from(
        new Set(params.tripartite_parameters_ids)
      );
      console.log(params,'addorEditshoquan');
      addkDicV(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("绑定成功！");
        } else {
          this.$message.error("绑定失败！原因是" + res.error);
        }
        this.shouquanDialogVisiable = false;
        this.getSensorType();
      });
    },
    editOneAuth() {
      let params = {
        parameters_type_id:
          this.selctionNodeRows[0].parameters_type_id,
        tripartite_parameters_ids: [],
      };
      this.$refs.tree.getCheckedNodes().forEach((v) => {
        params.tripartite_parameters_ids.push(v.tripartite_parameters_id);
      });
      params.tripartite_parameters_ids = Array.from(
        new Set(params.tripartite_parameters_ids)
      );
      console.log(params,'editOneAuth');
      addkDicV(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("绑定成功！");
        } else {
          this.$message.error("绑定失败！原因是" + res.error);
        }
        this.shouquanDialogVisiable = false;
        this.getSensorType();
      });
    },
    // 重置
    clearAll() {
      console.log('----------------')
      this.getSensorType();
      this.getSiteArr();
    },
    importEvent() {
      // 打开文件导入的弹窗
      this.centerDialogVisible = true;
    },
    exportEvent() {
      this.$message.success("导出按钮");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./table.scss";
.btnRow {
  display: block;
}

.unBtnRow {
  display: none;
}
::v-deep .custom-dialog .el-dialog__body {
  height: auto;
  overflow: hidden;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
  color: aliceblue;
  overflow: auto;
}

.openDig {
  display: flex;
  background-color: #285ed4c2;
}
.custom-dialog {
  ::v-deep .el-card__body,
  .el-main {
    padding: 5px;
  }

  ::v-deep .el-image__inner {
    margin-top: 20%;
  }

  .dga_data {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }

  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }

  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
}
::v-deep .el-tree {
  position: relative;
  cursor: pointer;
  background: transparent;
  color: #fff;
}
::v-deep .el-tree-node__content {
  &:hover {
    background: #6f9ec9;
  }
}
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: rgb(20, 107, 107) !important;
}
</style>

